import React from "react"
import { Link } from "gatsby"

import { withWishlistItem } from "./withWishlistItem"
import { Image } from "../../Image/Image"
import { Money } from "../../Money"
import { P } from "../../Styled/Text"
import { PrimaryButton } from "../../Styled/Button"
import { Card, Content, Details, DetailsWrapper, StyledTextButton } from "../WishlistStyles"

interface Props {
  condensed: boolean
  image: any
  item: any
  handleZoom: any
  handleMove: any
  handleRemove: any
  locales: any
  loading: boolean
  linkResolver: any
  product: any
  wishlistName: string
  variant?: any
}

export const WishlistItem = withWishlistItem(
  ({ condensed, handleMove, handleRemove, handleZoom, image, item, linkResolver, loading, locales, product, variant }: Props) => (
    <Card className={`group`} condensed={condensed}>
      <Content>
        <DetailsWrapper
          as={product ? Link : `button`}
          draggable={false}
          onClick={!product ? () => handleZoom(item.image) : null}
          to={product ? linkResolver(item, `product`)?.url : null}
        >
          <Image alt={image?.alt || `${item?.title} image`} ratio={`2/3`} src={image?.originalSrc || image} />
        </DetailsWrapper>
        {product ? (
          <>
            <DetailsWrapper to={linkResolver(item, `product`)?.url} as={Link} draggable={false}>
              <Details>
                <P>{item.vendor}</P>
                <P>{item.title}</P>

                {item?.variants?.length > 0 || item?.priceRange?.minVariantPrice ? (
                  <P>
                    <Money amount={item?.variants?.[0]?.priceV2 || item?.priceRange?.minVariantPrice} />
                  </P>
                ) : null}
              </Details>
            </DetailsWrapper>

            <PrimaryButton disabled={loading || (variant && !variant.availableForSale)} full={`true`} onClick={handleMove}>
              {variant && !variant.availableForSale ? "Out of stock" : locales.addToCart}
            </PrimaryButton>
          </>
        ) : (
          <DetailsWrapper>
            <Details>
              {item.title ? <P>{!item.type ? `${locales.name} ${item.title}` : item.title}</P> : null}
              {item.dress ? <P>{item.dress}</P> : null}
              {item.info ? <P>{`${locales.info} ${item.info}`}</P> : null}
            </Details>
          </DetailsWrapper>
        )}

        <StyledTextButton disabled={loading} onClick={handleRemove} small underlined>
          {locales.remove}
        </StyledTextButton>
      </Content>
    </Card>
  )
)
