import React from "react"

import { withWishlistShared } from "./withWishlistShared"
import { HeroBanner } from "../../Sections/HeroBanner/HeroBanner"
import { WishlistItem } from "../Item/WishlistItem"
import { SharedContainer, SharedItems } from "../WishlistStyles"

export const WishlistShared = withWishlistShared(({ hero, wishlist }) => (
  <>
    <HeroBanner section={hero} />
    <SharedContainer>
      <SharedItems>
        {wishlist?.map(item => (
          <WishlistItem key={item?.id} condensed item={item} />
        ))}
      </SharedItems>
    </SharedContainer>
  </>
))
